@use '@carbon/react/scss/config';
@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type';
@use '@carbon/react/scss/components/button';
@use '@carbon/react/scss/components/popover';
@use '@carbon/react/scss/components/tooltip';
@use '@carbon/react/scss/components/toggletip';
@use '@carbon/react/scss/components/loading';
@use '@carbon/react/scss/components/inline-loading';
@use '@carbon/react/scss/components/data-table';


@include button.button();
@include popover.popover();
@include tooltip.tooltip();
@include toggletip.toggletip();
@include loading.loading();
@include inline-loading.inline-loading();
@include data-table.data-table();


$carbon-prefix: config.$prefix;

.rh-search {

  .xf-i-tool {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
  }

  .#{$carbon-prefix}--data-table-header {
    .xf-i-stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: space-between;
      justify-content: space-between;
      margin: 1.5rem 3rem 0 2rem;

      .xf-i-stat {
        background-color: theme.$layer-02;
        border: 1px dashed theme.$border-subtle-03;
        padding: 0.5rem 1rem;
        margin: 0 2rem;
        flex: 1 1 auto;
        width: 180px;
        border-radius: 2px;
        box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.3);

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .#{$carbon-prefix}--loading + span {
            margin-top: 0.5rem;
            display: inline-block;
        }

        &.dns {
          // border-top: 1px dashed lighten(#0f62fe, 10%);  // Blue 60
          border-left: 2px solid #0f62fe;
        }

        &.crt {
          // border-top: 1px dashed #d12771;  // Magenta 70
          border-left: 2px solid #9f1853;
        }

        &.host {
          // border-top: 1px dashed #009d9a;  // Teal 50
          border-left: 2px solid #009d9a;
        }

        &.svc {
          // border-top: 1px dashed #8a3ffc;  // Purple 60
          border-left: 2px solid #8a3ffc;
        }

        &.acc {
          // border-top: 1px dashed #a2191f;  // Red 60
          border-left: 2px solid #a2191f;
        }

        &.rel {
          // border-top: 1px dashed #ba4e00;  // Orange 60
          border-left: 2px solid #ba4e00;
        }

        &.org {
          // border-top: 1px dashed #d2a106;  // Yellow 40
          border-left: 2px solid #d2a106;
        }

        h3 {
          @include type.type-style('productive-heading-06');
        }

        span {
          @include type.type-style('body-short-02');
        }
      }
    }

    .xf-dt-list {
      margin-left: 2rem;

      li {
        margin-bottom: 3px;
      }
    }

    .xf-desc-note {
      @include type.type-style('helper-text-01');
    }
  }

  .xf-i-main {
    position: relative;
    background: none;
    border: none;
    margin-top: 1rem;

    .react-grid-item > .react-resizable-handle {
      position: fixed;
      right: -4px;
      bottom: -4px;

      &::after {
        border-color: theme.$icon-secondary;
        width: 10px;
        height: 10px;
      }
    }

    .xf-i-card {
      //border-radius: 2px;
      background: theme.$layer-01;
      border: 1px solid theme.$layer-accent-01;
      border-top: 3px solid theme.$border-subtle-01;

      &.dns {
        border-top-color: #0f62fe;  // Blue 60
      }

      &.crt {
        border-top-color: #9f1853;  // Magenta 70
      }

      &.host {
        border-top-color: #009d9a;  // Teal 50
      }

      &.svc {
        border-top-color: #8a3ffc;  // Purple 60
      }

      &.acc {
        border-top-color: #a2191f;  // Red 60
      }

      &.rel {
        border-top-color: #ba4e00;  // Orange 60
      }

      &.org {
        border-top-color: #d2a106;  // Yellow 40
      }

      .xf-drag {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        margin: 4px 2px;
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }

      .xf-rel-card, .xf-org-card, .xf-service-card, .xf-crt-card, .xf-host-card, .xf-account-card, .xf-data-card, .xf-dns-card, .xf-dc-content {
      position: relative;
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;

        .loading-container {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          padding: 0;
          background: rgba(0, 0, 0, 0.8);
        }
      }

      .#{$carbon-prefix}--data-table-container {
        max-height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        .#{$carbon-prefix}--data-table-header {
          padding-bottom: 0;

          .#{$carbon-prefix}--data-table-header__description {
            .#{$carbon-prefix}--loading {
              display: inline-block;
              vertical-align: middle;
              margin-left: 0.5rem;
            }
          }
        }

        .#{$carbon-prefix}--table-toolbar {
          flex: 0 0 auto;
        }

        .#{$carbon-prefix}--pagination {
          bottom: 0;

          .#{$carbon-prefix}--select-input:disabled {
            display: none;

            & ~ .#{$carbon-prefix}--select__arrow {
              display: none;
            }
          }

          &.xl-page {
            .#{$carbon-prefix}--select__page-number {
              display: none;
            }
          }
        }

        .#{$carbon-prefix}--data-table-content {
          max-height: 100%;
          position: relative;

          .#{$carbon-prefix}--data-table {
            thead {
              position: sticky;
              top: 0;
              z-index: 1;

              .#{$carbon-prefix}--table-expand + th .#{$carbon-prefix}--table-sort{
                padding-left: 0;
              }
            }

            tbody {
              word-break: break-word;
              word-wrap: break-word;
              max-height: 100%;

              .#{$carbon-prefix}--expandable-row {

                .#{$carbon-prefix}--child-row-inner-container {
                  @include type.type-style('code-01');

                  word-break: break-all;
                  word-wrap: anywhere;
                  white-space: pre-wrap;

                  > div {
                    padding: 1rem 0 2rem;
                  }
                }

                //&.#{$carbon-prefix}--parent-row + .#{$carbon-prefix}--expandable-row td {
                //  background: $ui-02;
                //}

                //&.#{$carbon-prefix}--parent-row.#{$carbon-prefix}--expandable-row--hover + .#{$carbon-prefix}--expandable-row td,
                //&.#{$carbon-prefix}--parent-row:hover + .#{$carbon-prefix}--expandable-row td,
                //&.#{$carbon-prefix}--parent-row + .#{$carbon-prefix}--expandable-row:hover td,
                //&.#{$carbon-prefix}--parent-row + .#{$carbon-prefix}--expandable-row td:hover {
                //  background: $ui-background;
                //}

              }
            }

          }
        }
      }
    }
  }
}
