.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.loading-spinner h1 {
  color: #999 !important;
  font-size: 1.5rem;
  font-weight: 200;
}

.loading-container.loading-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 100px;
}

.loading-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.xfr-logo-box {
  width: 69px;
  height: 69px;
  display: flex;
  flex-wrap: wrap;
}

.xfr-logo-box > div {
  background-image: url(../../img/logo.svg);
  background-size: 50.48px 69px;
  background-repeat: no-repeat;
  -webkit-animation: xfrLogoAnimation 1.3s infinite ease-in-out;
  animation: xfrLogoAnimation 1.3s infinite ease-in-out;
  width: 23px;
  height: 23px;
  backface-visibility: hidden;
  transform: translateZ(0); /* "hack" to force hardware accelerated rendering */
  -webkit-font-smoothing: subpixel-antialiased;
}

.xfr-logo-box > div:nth-child(1) {
  background-position: 9.26px 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.xfr-logo-box > div:nth-child(2) {
  background-position: -13.74px 0;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.xfr-logo-box > div:nth-child(3) {
  background-position: -36.74px 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.xfr-logo-box > div:nth-child(4) {
  background-position: 9.26px -23px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.xfr-logo-box > div:nth-child(5) {
  background-position: -13.74px -23px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.xfr-logo-box > div:nth-child(6) {
  background-position: -36.74px -23px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.xfr-logo-box > div:nth-child(7) {
  background-position: 9.26px -46px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.xfr-logo-box > div:nth-child(8) {
  background-position: -13.74px -46px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.xfr-logo-box > div:nth-child(9) {
  background-position: -36.74px -46px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes xfrLogoAnimation {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}

@keyframes xfrLogoAnimation {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}
