@use '@carbon/react/scss/config';
@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type';
@use '@carbon/react/scss/components/button';
@use '@carbon/react/scss/components/popover';
@use '@carbon/react/scss/components/tooltip';
@use '@carbon/react/scss/components/toggletip';
@use '@carbon/react/scss/components/loading';
@use '@carbon/react/scss/components/inline-loading';
@use '@carbon/react/scss/components/data-table';
@use '@carbon/react/scss/components/data-table/sort';
@use '@carbon/react/scss/components/data-table/expandable';
@use '@carbon/react/scss/components/data-table/action';
@use '@carbon/react/scss/components/pagination';
@use '@carbon/react/scss/components/pagination-nav';


@include button.button();
@include popover.popover();
@include tooltip.tooltip();
@include toggletip.toggletip();
@include loading.loading();
@include inline-loading.inline-loading();
@include data-table.data-table();
@include sort.data-table-sort();
@include expandable.data-table-expandable();
@include action.data-table-action();
@include pagination.pagination();
@include pagination-nav.pagination-nav();


$carbon-prefix: config.$prefix;


@keyframes LoadingFrames {
  0%{background-position:10% 0}
  50%{background-position:91% 100%}
  100%{background-position:10% 0}
}

.xf-data-card {
  .xf-table-toolbar {
    display: flex;
  }

  .#{$carbon-prefix}--data-table-container {

    .#{$carbon-prefix}--data-table-content {
      margin-bottom: 2rem;
      height: 100%;
    }

    .#{$carbon-prefix}--pagination {
      min-height: 2rem;
      position: fixed;
      bottom: 0;

      &.pending {
        &::before {
          display: block;
          content: " ";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgb(0,0,0);
          background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 15%, rgba(0,0,0,1) 50%, rgba(0,0,0,0.5) 85%, rgba(0,0,0,0.5) 100%);
          background-size: 200% 200%;

          animation: LoadingFrames 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
          z-index: 1;
          cursor: progress;
        }
      }

      .#{$carbon-prefix}--pagination__left, .#{$carbon-prefix}--pagination__right {
        height: 2rem;

        .#{$carbon-prefix}--select-input {
          height: 2rem;
          max-height: 2rem;
          min-height: 2rem;
          line-height: 100%;
        }

        .#{$carbon-prefix}--select__item-count .#{$carbon-prefix}--select-input {
          padding-left: 3px;
        }

        .#{$carbon-prefix}--select__page-number {
          .#{$carbon-prefix}--select__arrow {
            right: 0;
          }

          .#{$carbon-prefix}--select-input {
            padding-right: 1.5rem;
            line-height: 100%;
          }
        }

        .#{$carbon-prefix}--pagination__control-buttons {
          max-height: 2rem;

          .#{$carbon-prefix}--pagination__button {
            height: 2rem;
            max-height: 2rem;
            min-height: 2rem;
          }
        }
      }
    }
  }
}

