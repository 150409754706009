@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type';
@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/breakpoint';
@use '@carbon/react/scss/components/button';
@use '@carbon/react/scss/grid';

@include button.button();
@include grid.flex-grid();


.xfr-landing-page {
  padding: 0;

  .xfr-landing-page__r2 .cds--tabs__nav {
    right: 0;
  }

  .xfr-landing-page__banner {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -(spacing.$spacing-05);
      top: 0;
      right: -(spacing.$spacing-05);
      bottom: 0;
      background: theme.$layer-01;
      z-index: -1;
    }

    .particle-banner {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      position: absolute;
      width: calc(100% + 3rem);
      height: 100%;
      z-index: 1;
    }

    .xfr-landing-page__banner_content {
      padding-top: spacing.$spacing-05;
      padding-bottom: spacing.$spacing-07 * 4;
      background: none;
      position: relative;
      display: flex;
      flex-direction: column;

      .xfr-landing-page__heading, .xfr-landing-page__subheading {
        position: relative;
        display: inline-block;
        z-index: 10;
      }

      .xfr-landing-page__subheading {
        margin-left: .5rem;
      }
    }
  }

  .xfr-landing-page__heading {
    @include type.type-style('fluid-heading-05', $fluid: true);

    .rh-red {
      color: #97354d;
      font-weight: 500;
      text-shadow: 2px 2px 2px rgba(0,0,0,0.7);
    }

    .rh-hive {
      font-weight: 500;
      color: #e0e0e0;
      text-shadow: 2px 2px 2px rgb(0, 0, 0);
    }
  }

  .xfr-landing-page__subheading {
    @include type.type-style('fluid-heading-03', $fluid: true);
  }

  .xfr-landing-page__r2 {
  }

  .xfr-landing-page-intro {
    background-color: theme.$layer-01;
    border-bottom: 1px solid theme.$layer-accent-01;
    padding: spacing.$spacing-10 spacing.$spacing-07;

    .hive-ico {
      position: relative;
      text-align: right;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      svg.hivelogo {
        width: 120px;
        height: 120px
      }
    }
  }

  .xfr-landing-page__subheading {
    @include type.type-style('heading-03');
    @include type.font-weight('semibold');
  }

  .xfr-landing-page__p {
    @include type.type-style('heading-03');
    margin-top: spacing.$spacing-05;
    margin-bottom: 0;
  }

  .xfr-landing-page__r3 {
    background-color: theme.$layer-01;
    position: relative;
    padding: spacing.$spacing-09 spacing.$spacing-07;

    &::before {
      content: '';
      position: absolute;
      left: -(spacing.$spacing-05);
      top: 0;
      right: -(spacing.$spacing-05);
      bottom: 0;
      background: theme.$layer-01;
      z-index: -1;
    }
  }

  .xfr-landing-page__label {
    @include type.type-style('heading-01');
  }

  .info-section {
    padding-top: spacing.$spacing-09;
    padding-bottom: spacing.$spacing-09;

    .info-section__heading {
      @include type.type-style('heading-01');
    }

    .info-card {
      padding-left: 1rem;
      margin-top: spacing.$spacing-09;
      display: flex;
      flex-direction: column;

      svg {
        margin-top: spacing.$spacing-09;
      }

      // top border in only small breakpoints to prevent overrides
      @include breakpoint.breakpoint-down(md) {
        &:not(:nth-child(2)) {
          border-top: 1px solid theme.$layer-accent-01;
          padding-top: spacing.$spacing-09;
        }
      }

      // left border in just the 2nd column items
      @include breakpoint.breakpoint(md) {
        &:nth-child(odd) {
          border-left: 1px solid theme.$layer-accent-01;
        }
      }

      // left border in all items
      @include breakpoint.breakpoint(lg) {
        margin-top: 0;
        border-left: 1px solid theme.$layer-accent-01;

        svg {
          margin-top: spacing.$spacing-12;
        }
      }
    }

    .info-card__heading {
      @include type.type-style('heading-03');
    }

    .info-card__body {
      margin-top: spacing.$spacing-06;
      flex-grow: 1; // fill space so icons are bottom aligned
      @include type.type-style('body-long-01');

      // prevent large line lengths between small and medium viewports
      @include breakpoint.breakpoint-between(321px, md) {
        max-width: 75%;
      }
    }
  }

  .xfr-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    color: #999;
    font-weight: 300;
    font-size: 0.8rem;
    text-align: left;
    padding: 1.5rem .5rem .5rem;

    .xfr-fl {
    }

    .xfr-author {
      flex: 0 1 auto;
      display: flex;

      .xf-icon {
        font-size: .9rem;
        line-height: .5rem;
      }

      .xf-repo {
        font-style: italic;
        color: #00bc8c;
        text-decoration: none;
        background-color: initial;
        margin-left: .25rem;

        &:hover {
          color: #007053;
          text-decoration: underline;
        }
      }
    }
  }
}
