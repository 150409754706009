@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type';
@use '@carbon/react/scss/components/notification';

@include notification.toast-notification();



body, html, main, main.cds--content, .cds--content {
  background-color: theme.$background;
  color: theme.$text-primary;
}

.toasty {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
}

.xf-unauthorised {
  height: 100vh;
  width: 100vw;

  .auth-box {
    width: 500px;
    height: 250px;
    padding: 20px;
    background: theme.$layer-01;
    position: absolute;
    left: calc(50% - 250px);
    top: calc(50% - 125px);
    border: 1px solid theme.$layer-accent-01;
    display: flex;
    flex-direction: column;

    .auth-head {
      @include type.type-style('heading-05');
      padding-bottom: 10px;
    }

    .auth-body {
      @include type.type-style('body-short-02');
      padding-bottom: 25px;
      flex: 1 1 auto;
    }

    .auth-foot {
      align-items: stretch;

      button {
        flex: 1 0 auto;
        max-width: 50%;
        box-shadow: none;
      }
    }
  }
}
