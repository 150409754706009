@use '@carbon/react/scss/config';
@use '@carbon/react/scss/components/button';
@use '@carbon/react/scss/components/popover';
@use '@carbon/react/scss/components/tooltip';


@include button.button();
@include popover.popover();
@include tooltip.tooltip();

$carbon-prefix: config.$prefix;


.xf-rel-card {

  .xf-rel-warn {

    .xf-rel-box {
      display: inline-block;
      padding-left: 0.2rem;

      .xf-rel-icon {
        border: none;
        background: none;
        outline : none;
        display: inline-block;
        padding: 0.1rem;
        color: #f4f4f4;
      }

      .#{$carbon-prefix}--popover {

      }
    }
  }
}

