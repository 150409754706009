@use '@carbon/react/scss/reset';
@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme' with (
  $fallback: themes.$white,
  $theme: themes.$g100,
);
@use '@carbon/react/scss/layer';

:root {
  // Emit CSS Custom Properties for the current theme
  @include theme.theme();
}

