
.particle-banner {
  position: relative;
  overflow: hidden;

  #tsparticles {
    width: 100%;
    height: 100%;
  }
}
