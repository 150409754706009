@use '@carbon/react/scss/config';
@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/components/ui-shell/content';
@use '@carbon/react/scss/components/ui-shell/header';
@use '@carbon/react/scss/components/ui-shell/header-panel';
@use '@carbon/react/scss/components/ui-shell/side-nav';
@use '@carbon/react/scss/components/ui-shell/switcher';
@use '@carbon/react/scss/components/popover';
@use '@carbon/react/scss/components/toggle';

@include content.content;
@include header.header;
@include header-panel.header-panel;
@include side-nav.side-nav;
@include switcher.switcher;
@include popover.popover;


$carbon-prefix: config.$prefix;

// some carbon style fixes :)
.#{$carbon-prefix}--header {

  .#{$carbon-prefix}--popover {
    @include theme.theme(themes.$white);
    @include popover.popover;

    .#{$carbon-prefix}--popover-content {
      background-color: var(--cds-popover-background-color, var(--cds-layer-01));
      color: var(--cds-popover-text-color, var(--cds-text-primary, #161616));
    }
  }

  .#{$carbon-prefix}--popover--bottom-right .#{$carbon-prefix}--popover-content {
    right: 1rem;
    padding: .25rem .5rem;
  }

  .#{$carbon-prefix}--popover--open.#{$carbon-prefix}--popover--caret {
    .#{$carbon-prefix}--popover-caret, .#{$carbon-prefix}--popover-content {
      display: block;
    }
  }

  .#{$carbon-prefix}--switcher {
    list-style: none outside none;
    padding: 0;
  }

  .#{$carbon-prefix}--header__name--prefix {
    margin-right: 0;
  }

  .#{$carbon-prefix}--header__nav {
    .#{$carbon-prefix}--header__menu-bar {
      .#{$carbon-prefix}--header__menu-item {
        height: calc(100% - 4px);
      }
    }
  }

  .#{$carbon-prefix}--header__name {
    height: calc(100% - 0.5rem);
  }
}


.xfr-main-header {

  .header-xfr-icon {
    fill: #f4f4f4;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .hdb-stats {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;

    .hdb-stat {
      + .hdb-stat {
        margin-left: 1.5rem;
      }
      .hs-lbl {
        margin: 0;
        color: #6f6f6f;
      }
      .hs-val {
        font-size: small;
        margin-left: 0.25rem;
        color: #c6c6c6;
      }
    }
  }

  .xfr-profile-pic, .xf-nav-ico {
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    color: #f4f4f4;
    fill: #f4f4f4;
    background: none;

    &:hover {
      background: #393939;
    }

    img {
      max-width: 32px;
      border-radius: 50%;
    }
  }

  .xfr-side-panel {
    display: flex;
    flex-direction: column;

    > div {
      width: 254px;
    }

    .xfr-hp-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .xfr-switcher {
        margin-top: 1rem;
      }
    }

    .xfr-user-info {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.16px;
      text-decoration: none;
      color: #c6c6c6;

      .xui-head {
        font-weight: 600;
        padding: 0.375rem 1rem;
      }

      .xui-sub {
        padding: 0.1rem 1.5rem;
      }
    }
  }

  .cds--header__name--prefix {
    margin-right: 0;
  }

  .xfr-divider {
    margin-right: 3px;
    font-weight: 400;
  }

  .hive-ico {
    display: inline-block;
    position: relative;

    svg.hivelogo {
      margin-top: -15px;
      margin-left: -5px;
    }
  }
}
